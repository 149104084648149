import { AuthProvider } from './context/auth';
import { AppRouter } from './routes';
import { ThemeProvider } from './themes';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
