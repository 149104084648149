import {
  DashboardOutlined as DashboardIcon,
  MenuOutlined as MenuIcon,
  CalendarMonthOutlined as CalendarIcon,
  ScheduleSendOutlined as ScheduleIcon,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export const PageToolbarMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const navigate = (pathname: string) => () => console.log(pathname);

  const popoverProps = {
    open: Boolean(menuAnchorEl),
    onClose: () => setMenuAnchorEl(null),
    anchorEl: menuAnchorEl,
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <Menu {...popoverProps}>
        <MenuItem onClick={navigate('/')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
