import { Box, Container, styled, Typography } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#F6F6F6',
  minHeight: 'calc(100vh - 88px)',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

export const ContentWrapper = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(3),
}))

export const ContentContainer = styled(Box)();

type PageTitleProps = {
  centered?: boolean;
}

export const PageTitle = styled(Typography)<PageTitleProps>(({ theme, centered }) => ({
  color: theme.palette.primary.main,
  textAlign: centered ? 'center' : 'left',
  marginBottom: theme.spacing(3),
}));