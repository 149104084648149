import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useMemo, useState } from 'react';

const PasswordFieldComponent = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return <TextField
    {...props}
    type={showPassword ? 'text' : 'password'}
    InputProps={{ endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(old => !old)}>
          {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>
      </InputAdornment>
    ) }}
  />
}

export const PasswordField = React.memo(PasswordFieldComponent);
