import { Avatar, Container, IconButton, Stack } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { AppBar, Toolbar } from "./styles";
import { PageToolbarMenu } from "./MenuList";
import { useContext } from "react";
import { AuthContext } from "../../context/auth";

export const PageToolbar = () => {
  const { singOut } = useContext(AuthContext);
  
  return (
    <AppBar position="sticky">
      <Container disableGutters>
        <Toolbar disableGutters>
          <Stack flexDirection="row" alignItems="center">
            <PageToolbarMenu />
          </Stack>
          <Stack flexDirection="row" justifyContent="center">
            <img
              src="/images/logo-rapidoc.png"
              alt="Logo Rapidoc"  
              style={{width: '210px'}}            
            />
          </Stack>
          <Stack alignItems="center" flexDirection="row" gap={1}>
            
            <IconButton
              size="large"
              onClick={singOut}
            >
              <Logout />
            </IconButton>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
