import { Stack } from "@mui/material";
import React from "react";
import { ContentWrapper, ContentContainer, PageTitle, Wrapper } from "./styles";
import { PageWrapperProps } from "./typings";

export const PageWrapper = ({
  pageTitle,
  pageTitleCentered,
  children,
  renderRightElement,
}: React.PropsWithChildren<PageWrapperProps>) => (
  <Wrapper>
    <ContentWrapper>
      <Stack flexDirection="row" alignItems="center" gap={2} mb={4}>
        <Stack>
          {!!pageTitle && (
            <PageTitle variant="h4" centered={pageTitleCentered} sx={{ p: 0, m: 0 }}>
              {pageTitle}
            </PageTitle>
          )}
        </Stack>
        {renderRightElement?.()}
      </Stack>
      <ContentContainer>{children}</ContentContainer>
    </ContentWrapper>
  </Wrapper>
);
