import axios, { AxiosInstance } from "axios";

// Configuração da instância base do axios
const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Configuração da instância para o dpanel usando a baseURL correta
const apiDPanel: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/dpanel/api',
});

// Interface para o parâmetro da função apiWhereIs
interface WhereIsParams {
  cpf: string;
  clientId: string;
  token: string
}

// Função que retorna uma instância do axios configurada com a baseURL específica
function apiWhereIs({ cpf, clientId, token }: WhereIsParams): AxiosInstance {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/tema/api/beneficiaries/${cpf}/whereis`,
    headers: {
      clientId,
      Authorization: `Bearer ${token}`
    },
  });
}

// Exportação das instâncias e da função
export default {
  api,
  apiDPanel,
  apiWhereIs
};
