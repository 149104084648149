import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import apiService from "../services/ApiService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const loadingStoreData = () => {
            const storageUser = localStorage.getItem("@Auth:user");
            const storageToken = localStorage.getItem("@Auth:token");

            if (storageUser && storageToken) {
                setUser(storageUser);
            }
        };
        loadingStoreData();
    }, []);

    const signIn = async ({ login, password }) => {
        const response = await apiService.apiDPanel.post("/users/auth", {
            login,
            password,
            expiresInMins: 30
        });
        
        if (response.data.error) {
            throw { message: response.data.error };
        } else {
            if (!response.data.rapidocAdmin) {
                throw { message: "Este usuário não tem permissão para entrar." };
            }

            setUser(response.data);
            // apiService.apiDPanel.defaults.headers.common[
            //     "Authorization"
            // ] = `Bearer ${response.data.token}`;

            localStorage.setItem("@Auth:user", JSON.stringify(response.data));
            localStorage.setItem("@Auth:token", response.data.apiToken);
        }
    };

    const singOut = () => {
        localStorage.clear();
        setUser(null);
        return <Navigate to="/" />;
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                signIn,
                singOut,
                signed: !!user,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};