import styled from "@emotion/styled";
import { AppBar as MUIAppBar, Toolbar as MUIToolbar } from "@mui/material";

export const AppBar = styled(MUIAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
}))

export const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));
