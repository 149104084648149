import styled from "@emotion/styled";
import { Container as MUIContainer, Paper, Stack } from "@mui/material";

export const ContainerWrapper = styled(Stack)(({ theme }) => ({
  background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`,
  height: '100vh',
  justifyContent: 'center',
}));

export const Container = styled(MUIContainer)(({ theme }) => ({
  disableGutters: true,
  width: '580px',
  maxWidth: '100%',
}))

export const LoginContainer = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(7.5),
  paddingBottom: theme.spacing(7.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderRadius: theme.spacing(1.5),
  elevation: 4,
}))

