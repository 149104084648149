export const serviceType = (value: string | null | undefined) => {
    
    switch (value) {
        case 'G':
            return 'Clínico Geral';
        case 'P':
            return 'Pisicologia';
        case 'GP':
            return 'Clínico Geral + Psicologia';
        case 'GS':
            return 'Clínico Geral + Especialistas';
        case 'GSP':
            return 'Clinico Geral + Especialistas + Psicologia';
        default:
            return '';

    }
}