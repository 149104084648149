import { Navigate } from "react-router-dom";
import { useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../context/auth";

import * as S from "./styles";
import { Alert, Button, Stack, TextField, Typography } from "@mui/material";
import { PasswordField } from "../../components/PasswordField";
import { WidthFull } from "@mui/icons-material";

export const Login = () => {
  const { signIn, signed } = useContext(AuthContext);
  const [values, setValues] = useState<Record<string, any>>({});
  const [error, setError] = useState<String>()
  const [spinner, setSpinner] = useState<boolean>()

  const register =
    (fieldName: string) => (evt: React.ChangeEvent<HTMLInputElement>) =>
      setValues((prev) => ({ ...prev, [fieldName]: evt.target.value }));

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (spinner) return;

      const data = {
        login: values.login,
        password: values.password,
      };

      setError(undefined)
      setSpinner(true)
      await signIn(data);
      setSpinner(false);
    } catch (e: any) {
      if ('response' in e) {
        setError(e.response.data.error);
      } else {
        setError(e.message)
      }

      setSpinner(false)
    }
  };

  const isValid = !!values.login && !!values.password;

  if (!signed) {
    return (
      <S.ContainerWrapper>
        <S.Container>
          <S.LoginContainer>
            <Stack spacing={4} alignItems="center">
              <Typography variant="h4" textAlign="center" color="primary.main">
                Where Is
              </Typography>
              <img
                src="/images/logo-rapidoc.png"
                alt="Logo Rapidoc"
                style={{ width: 290, marginBottom: 20 }}
              />

              {error && (
                <Alert key="alert-error" severity="error" sx={{ width: "100%" }} variant="standard">{error}</Alert>
              )}

              <Stack spacing={2} alignSelf="stretch">
                <TextField
                  placeholder="000.000.000-00"
                  label="Email"
                  size="medium"
                  onChange={register("login")}
                />
                <PasswordField
                  label="Senha"
                  placeholder="******"
                  onChange={register("password")}
                />
              </Stack>
              <Stack spacing={2} alignSelf="stretch">
                <Button
                  type="button"
                  variant="contained"
                  disabled={isValid === false}
                  onClick={handleSubmit}
                  style={{ padding: "11px 0" }}
                >
                  {spinner ? 'AGUARDE..' : 'ENTRAR'}
                </Button>
              </Stack>
            </Stack>
          </S.LoginContainer>
        </S.Container>
      </S.ContainerWrapper>
    );
  } else {
    return <Navigate to="/home" />;
  }
};