
import { Box, Button, Container, Icon, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ApiService from "../services/ApiService";
import { LocalAuth } from "../context/localAuth";
import { UserModel } from "../models/user.model";
import { BeneficiaryModel } from "../models/beneficiary.model";
import { PageToolbar } from "../components/PageToolbar";
import { PageWrapper } from "../components/PageWrapper";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ItemWidget from "../components/ItemWidget";
import { serviceType } from "../utils/serviceType";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    width: { xs: '100%', sm: 500, md: 600 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Home = () => {
    const [search, setSearch] = useState('');
    const [spinner, setSpinner] = useState<boolean>(false)
    const [list, setList] = useState<BeneficiaryModel[]>([])
    const localAuth = LocalAuth();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<BeneficiaryModel>()

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        var user: UserModel = localAuth.storageUser
        setSpinner(true);

        const apiInstance = ApiService.apiWhereIs({
            cpf: search,
            token: user.apiToken,
            clientId: user.clientUuid
        });

        const response = await apiInstance.get('')
        setSpinner(false);

        if (response.data.error) {
            alert(response.data.error);
        } else {
            setList(response.data.beneficiaries)
        }

    }

    const handleOpenModal = (item: BeneficiaryModel) => {
        setSelected(item);
        setOpen(true);
    }


    return (
        <div>
            <PageToolbar />

            <PageWrapper>
                <Container>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            id="outlined-basic"
                            label="CPF"
                            variant="outlined"
                            placeholder="Digite o CPF..."
                            sx={{ marginRight: 2, flex: 1, '& .MuiInputBase-root': { height: '45px', paddingBottom: 3, paddingTop: 3 } }}
                            onChange={(e: any) => setSearch(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ padding: '8px 16px', minWidth: 'auto', height: '46px' }}
                            onClick={handleSubmit}
                        >
                            {spinner ? 'BUSCANDO' : 'BUSCAR'}
                        </Button>
                    </Box>


                    {list.length == 0 && (
                        <Typography color="GrayText" marginTop={3}>
                            Não há registros no momento.
                        </Typography>
                    )}

                    {list.length > 0 && (
                        <TableContainer style={{ marginTop: '20px' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>Parceiro</TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>CPF</TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>Nascimento</TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => { handleOpenModal(item) }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell component="th" scope="row">{item.name}</TableCell>
                                            <TableCell align="right">{item.clientName}</TableCell>
                                            <TableCell align="right">{item.cpf}</TableCell>
                                            <TableCell align="right">{item.birthday}</TableCell>
                                            <TableCell align="right"><RemoveRedEyeIcon /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Container>
            </PageWrapper>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
                            {selected?.name}
                        </Typography>
                        <CloseIcon fontSize="large" cursor="pointer" onClick={handleClose} />
                    </Stack>
                    <Stack id="modal-modal-description" sx={{ mt: 2 }} spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                            <ItemWidget label="CPF" value={selected?.cpf} />
                            <ItemWidget label="Nascimento" value={selected?.birthday} />
                            <ItemWidget label="E-mail" value={selected?.email} />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <ItemWidget label="Endereço" value={`${selected?.address} | ${selected?.city}-${selected?.state}`} />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6}>
                            <ItemWidget label="Status" value={selected?.isActive ? 'Ativo' : 'Inativo'} />
                            <ItemWidget label="Pagamento" value={selected?.paymentType == 'S' ? 'Recorrência' : 'Consulta'} />
                            <ItemWidget label="Serviço" value={serviceType(selected?.serviceType)} />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={7}>
                            <ItemWidget label="Parceiro" value={selected?.clientName} />
                        </Stack>
                    </Stack>
                </Box>
            </Modal>


        </div>
    )
}

export default Home;