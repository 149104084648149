import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    primary: {
      main: '#030A33',
    },
    secondary: {
      main: '#192EB2',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h4: {
      fontSize: '30px',
      fontWeight: 600,
      lineHeight: '36px',
    },
  },
})
