import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';

import { defaultTheme } from './default';

export const ThemeProvider = ({ children }: React.PropsWithChildren) => (
  <EmotionThemeProvider theme={defaultTheme}>
    <MUIThemeProvider theme={defaultTheme}>
      {children}
    </MUIThemeProvider>
  </EmotionThemeProvider>
)