import { Box, Typography } from "@mui/material";

type Props = {
    label: string,
    value: any,
}

function ItemWidget({label, value}: Props) {
    return ( 
        <Box>
            <Typography fontWeight="bold">{label}</Typography>
            <Typography>{value}</Typography>
        </Box>
     );
}

export default ItemWidget;