import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { PrivateRoute } from "./privateRoutes";
import Home from "../pages/Home";
import { Login } from "../pages/login/Login";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}

        <Route path="/home" element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
};